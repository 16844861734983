import React from 'react'
import { Link } from "react-router-dom";
import Gotaproject from './Gotaproject'

export default function Whatwedo() {
  return (
    <div>
         <div>
        <div>
        <div className='custom-banner'>
        <div className='container-fluid'>
        <div className='banner-content'>
                <h2>We Transform Businesses through technology</h2>
              <p>At Amsys, we're not just a software development company; we're your growth partner. With our comprehensive software development services, you can streamline your operations, connect with your audience, and stay competitive in a rapidly evolving digital landscape.</p>
              <Link to="/bookfreeconsultation">Book Free Consultation</Link>
            </div>
        </div>
            <div className='custom-bnr-video'>
            <video playsinline="playsinline" muted="muted" preload="yes" autoplay="autoplay" loop="loop" id="vjs_video_739_html5_api" className="video-js" data-setup='{"autoplay":"any"}'>
    		<source src="image/whatwedo.mp4" type="video/mp4"/>
      </video>
            </div>
        </div>

        <section className='transform-your-business whatwedo-main'>
            <div className='container-fluid'>
                <div className='business-heading'>
                    <h2>Our Areas Of Expertise</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <span><img src="image/1.svg" alt="" /></span>
                            <h3>Software Development</h3>
                            <ul>
                            <li>Breathe life into your ideas with our versatile software development, crafting innovative solutions that exceed expectations.</li>
            <li>Bridge the gap between vision and reality with our expert craftsmanship, delivering robust, scalable software tailored to your needs.</li>
            <li>Fuel efficiency and dexterity with cutting-edge technologies and methodologies, ensuring a competitive edge in today's dynamic landscape.</li>
            <li>Foster seamless collaboration and transparent communication, guaranteeing a successful journey from concept to deployment.</li>
        
                            </ul>
                            <Link to="/softwaredevelopment">Learn More</Link>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/2.svg" alt="" /></span>
                            <h3>Cloud Solutions</h3>
                            <ul>
                            <li>Unleash limitless potential with our scalable cloud solutions, empowering agility, innovation, and cost-effective growth.</li>
            <li>Simplify your IT landscape with secure, reliable cloud infrastructure, eliminating complexity and boosting operational efficiency.</li>
            <li>Harness the power of data-driven insights with our advanced cloud analytics, fueling smarter decision-making and competitive advantage.</li>
            <li>Elevate your security posture with industry-leading cloud security measures, ensuring peace of mind and data protection.</li>
                            </ul>
                            <Link to="/CloudSolutions">Learn More</Link>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content pd-right'>
                        <span><img src="image/3.svg" alt="" /></span>
                            <h3>Cyber Security</h3>
                            <ul>
                            <li>Fortress your defenses with comprehensive cybersecurity solutions, safeguarding your data and ensuring digital resilience.</li>
            <li>Proactively combat evolving threats with our expert threat intelligence and incident response, minimizing downtime and maximizing security.</li>
            <li>Empower your team with security awareness training and tailored solutions, building a culture of cyber vigilance.</li>
            <li>Navigate the compliance landscape with confidence, leveraging our expertise to meet industry standards and regulations.</li>
                            </ul>
                            <Link to="/cybersecurity">Learn More</Link>
                            
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/4.svg" alt="" /></span>
                            <h3>ERP Solution</h3>
                            <ul>
                            <li>Unify your operations with a streamlined ERP, unlocking data-driven insights for smarter decision-making.</li>
            <li>Automate workflows, boost efficiency, and eliminate silos with a centralized platform for all your core business processes.</li>
            <li>Gain real-time visibility and control over your entire organization, from finance and inventory to production and customer service.</li>
            <li>Scale effortlessly, future-proof your growth, and drive bottom-line results with a flexible and adaptable ERP solution.</li>
                            </ul>
                            <Link to="/erpsolutions">Learn More</Link>
                           
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/5.svg" alt="" /></span>
                            <h3>Mobile App Development</h3>
                            <ul>
                            <li>Craft captivating mobile apps that ignite engagement, fueled by our expert development and design mastery.</li>
            <li>Streamline your workflow, boost productivity, and empower your users with custom mobile solutions tailored to your unique needs.</li>
            <li>Reach new audiences and unlock untapped potential with future-proof apps optimized for all platforms and devices.</li>
            <li>Collaborate with our agile team to transform your vision into reality, ensuring a seamless journey from concept to launch.</li>
        
                            </ul>

                            <Link to="/Mobileappdevelopment">Learn More</Link>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content pd-right'>
                        <span><img src="image/6.svg" alt="" /></span>
                            <h3>Data Center Solutions</h3>
                            <ul>
                            <li>Fortify your digital foundation with secure, scalable data center solutions built for peak performance and unwavering reliability.</li>
            <li>Optimize efficiency and costs with expert infrastructure management, ensuring seamless operations and maximum uptime.</li>
            <li>Safeguard your critical data with robust security measures and proactive threat mitigation, enabling complete peace of mind.</li>
            <li>Navigate your hybrid cloud journey with seamless integration and migration services, empowering agility and innovation.</li>
                            </ul>

                            <Link to="/datacentersolutions">Learn More</Link>

                        </div>
                    </div>
                </div>
               
            </div>
        </section>

        <Gotaproject />

<section className='custom-latest-work'>
  <div className='container-fluid'>
    <div className='row'>
        <div className='col-lg-6'>
            <div className='latest-inner'>
                <h3>Our Latest Work</h3>
                <p>Since 2106, Amsys has been at the forefront of delivering innovative solutions across various industries, including inventory management, HRMS, web, and mobile applications.
                    
                </p>
                <p> Our expertise extends to precise part-level management, ensuring efficient inventory tracking and optimization. Additionally, we specialize in developing robust web and mobile applications tailored to meet the unique needs of our clients.</p>
                <Link to="/ourwork">Explore our Work</Link>
            </div>
        </div>
        <div className='col-lg-6'>
            <div className='latest-inner-img'>
                <section className="a-section">
                    <div className="a-section-marquee-box">
                    <h2 className="marquee-text"><img src="image/test/1.png" alt="" /></h2>
                    <h2 className="marquee-text"> <img src="image/test/2.png" alt="" /></h2>
                    <h2 className="marquee-text"><img src="image/test/3.png" alt="" /></h2>
                    <h2 className="marquee-text"> <img src="image/test/4.png" alt="" /></h2>
                    </div>
                </section>

                <section className="b-section">
                    <div className="b-section-marquee-box">
                    <h2 className="marquee-text"> <img src="image/test/5.png" alt="" /></h2>
                    <h2 className="marquee-text"> <img src="image/test/6.png" alt="" /></h2>
                    <h2 className="marquee-text"><img src="image/test/7.png" alt="" /></h2>
                    <h2 className="marquee-text"> <img src="image/test/8.png" alt="" /></h2>
                    </div>
                </section>
                <section className="a-section">
                    <div className="a-section-marquee-box">
                    <h2 className="marquee-text"><img src="image/test/9.png" alt="" /></h2>
                    <h2 className="marquee-text"> <img src="image/test/10.png" alt="" /></h2>
                    <h2 className="marquee-text"><img src="image/test/11.png" alt="" /></h2>
                    <h2 className="marquee-text"> <img src="image/test/12.png" alt="" /></h2>
                    </div>
                </section>
            </div>
        </div>
    </div>   
 </div>    
</section> 


    </div>
    </div>
    </div>
  )
}
