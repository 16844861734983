import React from 'react'
import Gotaproject from './Gotaproject.js'
import { Link } from "react-router-dom";
export default function Datacentersolutions() {
  return (
    <div>
         <div>
        <div>
        <div className='custom-banner'>
        <div className='container-fluid'>
        <div className='banner-content'>
                <h2>Data Center Solutions</h2>
                <p>Take your data management to new heights with our cutting-edge Data Centre Solutions. We effortlessly combine innovation and efficiency to redefine security and operations for your digital assets. Our entire package extends beyond storage to include infrastructure design, disaster recovery, enhanced security, and more. 
</p>
<p>We allow you to maximise the potential of your data by combining dependability, scalability, and steadfast security. Our innovative Data Centre Solutions can help you unlock creativity, accelerate development, and prosper in the ever-changing digital world.</p>
<Link to="/bookfreeconsultation">Book Free Consultation</Link>
            </div>
        </div>
            <img className='bnr-height' src="image/datacenter.png" alt="" />
        </div>

        <section className='transform-your-business software-development'>
            <div className='container-fluid'>
                <div className='business-heading'>
                    <h2>Data Center Solutions</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Infrastructure Design </h3>
                           <p>Optimize efficiency with our data center solutions, featuring state-of-the-art infrastructure design. Meticulously tailored for seamless operations, we ensure maximal resource utilization, resulting in heightened performance. Our approach prioritizes a robust and finely tuned infrastructure for your business needs.</p>
                            </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Disaster Recovery Planning</h3>
                            <p>Mitigate potential risks with our robust disaster recovery planning services, a crucial element in safeguarding your data and ensuring business continuity in the face of unforeseen events. Our comprehensive strategies not only identify potential risks but also provide a resilient framework for quick recovery</p>
                           </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Advanced Security Protocols</h3>
                          <p>Ensure the integrity and confidentiality of your data with our advanced security protocols. Our measures include state-of-the-art encryption and multi-layered access controls, prioritizing robust data protection. Implementing these features fortifies your security posture and safeguards sensitive information.</p>
                          </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3> Virtualisation Services</h3>
                          <p>Embrace unparalleled flexibility and resource efficiency through our virtualisation services. By optimising the utilisation of hardware resources, we enhance overall performance and adaptability. It not only streamlines your infrastructure but also ensures a dynamic allocation of resources.</p>
                            </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Cloud Integration</h3>
                            <p>Unlock scalable solutions with seamless cloud integration, enhancing data accessibility and collaboration. Our expertise ensures a smooth transition, providing your organization with agility and scalability. Leverage the power of cloud services for heightened efficiency in your operations.</p>
                            </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Scalable Architectures</h3>
                            <p>Our data centre solutions are distinguished by scalable architectures, allowing your infrastructure to grow seamlessly with the evolving demands of your business. This commitment to scalability ensures long-term sustainability and innovation, positioning your organisation for continued success in a dynamic business environment.</p>
                            </div>
                    </div>
                    
                </div>
               
            </div>
        </section>


        <Gotaproject />

        <section className='transform-your-business'>
            <div className='container-fluid'>
                <div className='business-heading'>
                    <h2><span>Key Benefits</span> of Data Center Solutions</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <span><img src="image/app3.png" alt="" /></span>
                            <h3>Optimised Performance</h3>
                            <p>Deliver an unparalleled user experience with our high-performance mobile applications, guaranteeing smooth and responsive interactions. By prioritizing optimized performance, we elevate your mobile solutions to surpass user expectations, setting a new standard for excellence in usability and satisfaction.</p>
                           </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/s2.png" alt="" /></span>
                            <h3>Cost-Efficiency</h3>
                       <p>Transform your cost model and do away with the requirement for a large physical infrastructure by utilising our data centre solutions. You pay for the resources you really use, which reduces operating expenses and frees up capital for more wise resource allocation.</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content pd-right'>
                        <span><img src="image/e1.png" alt="" /></span>
                            <h3>Seamless Cloud Integration</h3>
                            <p>Foster a culture of seamless collaboration among teams, transcending geographical barriers. With shared and real-time access to cloud-based data and applications, your teams can work cohesively, driving productivity and innovation.
</p>
                         </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/s1.png" alt="" /></span>
                            <h3>Scalability and Flexibility</h3>
                            <p>Seamlessly scale resources in real-time to meet fluctuating demands, providing your business with the agility, flexibility, and cost-efficiency required for sustained growth. Our data centre solutions adapt dynamically to evolving needs, ensuring optimal performance and resource utilisation.
</p>
                         </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/s5.png" alt="" /></span>
                            <h3>Reliability & Business Continuity</h3>
                            <p>Fortify operations with our data centre solutions' redundancy and disaster recovery capabilities. Safeguard against unforeseen events, maintaining business continuity and providing uninterrupted services to clients. Rely on our robust infrastructure for resilience and peace of mind in potential disruptions.</p></div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content pd-right'>
                        <span><img src="image/app6.png" alt="" /></span>
                            <h3>Scalability for Future Growth</h3>
                            <p>Benefit from scalable architectures allowing your infrastructure to seamlessly grow with evolving demands, ensuring long-term sustainability and innovation. Embrace a future-ready foundation that empowers agility and positions your organization for continued success in a dynamic market landscape.</p>
                      </div>
                    </div>
                </div>
               
            </div>
        </section>

        <section className='custom-faq'>
    <div className='container-fluid'>
        <div className='faq-inner'>
        <div className="accordion">
    <h1>Frequently Asked Questions</h1>
    <div className="accordion-item">
        <input type="checkbox" id="accordion1" />
        <label for="accordion1" className="accordion-item-title"><span className="icon"></span> How does the data center solution enhance operational efficiency?</label>
        <div className="accordion-item-desc">Our solution optimizes resource utilization and computing performance for seamless operations.

</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion2" />
        <label for="accordion2" className="accordion-item-title"><span className="icon"></span>What security measures are in place for data protection and compliance?</label>
        <div className="accordion-item-desc"> Advanced protocols, encryption, and access controls ensure data security and compliance with industry standards.

</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion3" />
        <label for="accordion3" className="accordion-item-title"><span className="icon"></span>How does the data center solution ensure business continuity in case of a disaster?
</label>
        <div className="accordion-item-desc">Robust disaster recovery planning safeguards data, ensuring uninterrupted business continuity.
</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion4" />
        <label for="accordion4" className="accordion-item-title"><span className="icon"></span> Can the data center solution accommodate our growing infrastructure needs?
</label>
        <div className="accordion-item-desc">Yes, our scalable architectures allow seamless growth to meet evolving business demands.


</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion5" />
        <label for="accordion5" className="accordion-item-title"><span className="icon"></span>What are the benefits of virtualization services in the data center solution?</label>
        <div className="accordion-item-desc">Virtualization enhances flexibility and resource efficiency, optimizing hardware resources for improved performance.</div>
    </div>

</div>
        </div>
    </div>
</section>

    </div>
    </div>
    </div>
  )
}
