import React from 'react'
import Form from './Form'

export default function Services() {
  return (
    <div>
        <div className='custom-banner'>
        <div className='container-fluid'>
        <div className='banner-content'>
                <h2>Software Development</h2>
                <p>We are a software development company providing cutting-edge solutions for businesses of all sizes like start-ups, SMBs and enterprises in different industry verticals. We have helped many corporate visionaries achieve their goals by developing innovative solutions using emerging technologies. </p>
                <p>We offer end-to-end software development services specifically designed to meet business goals at reduced costs. Amsys’s expert developers help to solve all your complex business problems at once!</p>
                <a>Get A Quote</a>
               
            </div>
        </div>
            <img className='bnr-height' src="image/software.png" alt="" />
        </div>

        <section className='transform-your-business software-development'>
            <div className='container-fluid'>
                <div className='business-heading'>
                    <h2>Software Development Services</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Custom Software Development</h3>
                            <p>At Amsys, we understand the individuality of each business and the importance of a tailored software solution. Our custom software development services are meticulously designed to cater to your specific requirements, offering a personalised digital foundation for your business success.</p>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Software Consulting Services</h3>
                           <p>Amsys serves as your strategic ally, providing exceptional Software Consulting Services to steer your business towards digital excellence. Our experienced consultants bring extensive expertise to the forefront, ensuring a customized journey that aligns with your specific goals.</p>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Software Integration</h3>
                            <p>Amsys excels in delivering effortless software integration services, harmonising diverse systems and applications seamlessly. We ensure smooth integration, whether it's with third-party software or legacy systems, enhancing efficiency and reducing operational complexities.</p>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>API Development Services</h3>
                            <p>Amsys is at the forefront of delivering cutting-edge API development services to enhance the functionality of your software. We meticulously design robust and scalable APIs, seamlessly integrating with third-party services and applications.</p>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>CRM Development Services</h3>
                           <p>Amsys delivers personalized CRM development services meticulously designed to align with the unique requirements of your business. Our custom CRM solutions empower effective customer relationship management, optimize sales processes, and enhance customer satisfaction.</p>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Web Application</h3>
                            <p>At Amsys, we specialize in crafting sophisticated web applications that redefine digital experiences. From intuitive interfaces to robust backend architectures, we tailor solutions to meet your unique business needs, enhancing user engagement and driving measurable results.</p>
                        </div>
                    </div>
                    
                </div>
               
            </div>
        </section>


      <section className='get-in-touch-main'>
         <div className='container-fluid'>
            <div className='get-in-touch-inner'>
                <div className='row'>
                    <div className='col-lg-8'>
                        <div className='get-in-touch-content'>
                            <h3>Got a project?</h3>
                            <p>Extend your digital presence seamlessly to both mobile and web platforms by leveraging the latest trends and cutting-edge technologies.</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='get-in-touch-btn'>
                            <a href="#">Get Free Consultation</a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>

        <section className='transform-your-business we-offer'>
            <div className='container-fluid'>
                <div className='business-heading'>
                    <h2><span>Key Benefits</span> in Custom Software 
Development Services that we offer</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <span><img src="image/a1.png" alt="" /></span>
                            <h3>Adaptable Environment</h3>
                            <p>Custom software development offers businesses a tailored solution that adapts seamlessly to their unique needs, fostering an environment of flexibility and scalability. 
This approach ensures quick responses to market changes, optimal workflows, and efficient integration with existing systems</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/a2.png" alt="" /></span>
                            <h3>Cost-Saving</h3>
                          <p>Embracing custom software development translates into substantial cost savings for businesses. The tailored nature of these solutions ensures a targeted investment, sparing companies from unnecessary expenses on features that don't align with their needs.</p>
                           
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content pd-right'>
                        <span><img src="image/a3.png" alt="" /></span>
                            <h3>Time-Effective</h3>
                           <p>Business exploration, digital plan crafting
Holistic project management, user satisfaction
Skilled developers, cutting-edge technology
Consistent Agile delivery, tailored methodologies
</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/a4.png" alt="" /></span>
                            <h3>Offline support</h3>
                           <p>Business exploration, digital plan crafting
Holistic project management, user satisfaction
Skilled developers, cutting-edge technology
Consistent Agile delivery, tailored methodologies
</p>
                           
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/a5.png" alt="" /></span>
                            <h3>Cross-platform compatibility</h3>
                            <p>Business exploration, digital plan crafting
Holistic project management, user satisfaction
Skilled developers, cutting-edge technology
Consistent Agile delivery, tailored methodologies
</p>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content pd-right'>
                        <span><img src="image/a6.png" alt="" /></span>
                            <h3>Easy maintenance and updates</h3>
                          <p>Business exploration, digital plan crafting
Holistic project management, user satisfaction
Skilled developers, cutting-edge technology
Consistent Agile delivery, tailored methodologies
</p>
                        </div>
                    </div>
                </div>
               
            </div>
        </section>

<section className='custom-faq'>
    <div className='container-fluid'>
        <div className='faq-inner'>
        <div className="accordion">
    <h1>Frequently Asked Questions</h1>
    <div className="accordion-item">
        <input type="checkbox" id="accordion1" />
        <label for="accordion1" className="accordion-item-title"><span className="icon"></span> How can Custom Software Development benefit my business specifically?</label>
        <div className="accordion-item-desc">Our Custom Software Development services are tailored to address your unique business challenges, providing solutions that enhance efficiency, productivity, and overall performance. Whether automating processes or creating bespoke applications, we ensure that our solutions align seamlessly with your business goals.</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion2" />
        <label for="accordion2" className="accordion-item-title"><span className="icon"></span>What sets your Software Consulting Services apart from others in the market?</label>
        <div className="accordion-item-desc">Our Software Consulting Services go beyond providing recommendations; we offer strategic insights to optimize your software ecosystem. We collaborate closely with you to understand your business objectives, enabling us to deliver personalized consulting that transforms your software landscape into a strategic asset.</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion3" />
        <label for="accordion3" className="accordion-item-title"><span className="icon"></span>How does Software Integration contribute to a more efficient business operation?</label>
        <div className="accordion-item-desc"> Software Integration is the key to streamlining your diverse platforms and systems. Our expertise ensures that your software components work in harmony, promoting smooth data exchange and eliminating silos. This integration facilitates a more cohesive and efficient business operation.
</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion4" />
        <label for="accordion4" className="accordion-item-title"><span className="icon"></span>Can you explain the importance of API Development Services in today's digital landscape?</label>
        <div className="accordion-item-desc"> API Development Services play a crucial role in connecting disparate systems, enabling seamless data communication. Our API solutions empower your business with the flexibility to adapt and integrate with evolving technologies, fostering innovation and future-proofing your operations.
</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion5" />
        <label for="accordion5" className="accordion-item-title"><span className="icon"></span>How will CRM Development Services enhance my customer relationships and business processes?</label>
        <div className="accordion-item-desc">Our CRM Development Services are designed to optimize customer interactions and boost operational efficiency. By implementing tailored CRM solutions, we help you centralize customer data, automate tasks, and enhance communication, ultimately fostering stronger customer relationships and improving overall business performance.</div>
    </div>

</div>
        </div>
    </div>
</section>
<Form />
    </div>
  )
}
