import React from "react";
import Slider from "react-slick";
import SliderData from "../components/Sliderdata";
import { Link } from "react-router-dom";

function Slickslider() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };

    function CustomPrevArrow(props) {
        const { className, onClick } = props;
        return (
            <button className={className} onClick={onClick}>
                Previous
            </button>
        );
    }

    function CustomNextArrow(props) {
        const { className, onClick } = props;
        return (
            <button className={className} onClick={onClick}>
                Next
            </button>
        );
    }

    return (
        <div className="alignCenter">
            <div className="loginSliderSec">
                <Slider {...settings}>
                    {SliderData.map((sliderData, index) => (
                        <div className="TextSlide" key={index}>
                            <img src={sliderData.image} alt="slider" />
                            <div className="container-fluid">
                                <div className="aLLTextSlider">
                                    <h2>{sliderData.hadd}</h2>
                                    <p>{sliderData.parr}</p>
                                    <img src={sliderData.shortimage} alt="slider" />
                                    <br />
                                    <br />
                                    <Link to={sliderData.linkurl}>Get Started</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default Slickslider;
