import React from 'react'
import Gotaproject from './Gotaproject.js';
import { Link } from "react-router-dom";
export default function CloudSolutions() {
  return (
    <div>
         <div>
        <div>
        <div className='custom-banner'>
        <div className='container-fluid'>
        <div className='banner-content'>
                <h2>Cloud Solutions</h2>
                <p>Embark on a revolution with our next-generation cloud solutions, expertly developed to drive your company into the digital forefront. We transcend traditional boundaries by seamlessly merging innovation and efficiency, delivering exceptional agility. Secure data storage, seamless communication, and cutting-edge analytics - our full package enables you to expand with confidence and operate with unparalleled dynamism. Unleash the cloud's transformational potential to prosper in the current corporate landscape.</p>
                <Link to="/bookfreeconsultation">Book Free Consultation</Link>
            </div>
        </div>
            <img className='bnr-height' src="image/cloud-banner.png" alt="" />
        </div>

        <section className='transform-your-business software-development'>
            <div className='container-fluid'>
                <div className='business-heading'>
                    <h2>Cloud Solutions</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Cloud Infrastructure</h3>
                        <p>Embrace the future with our Cloud Infrastructure solutions, a scalable foundation for digital operations. Our robust framework ensures unparalleled efficiency, reliability, and seamless adaptation to evolving company needs. Innovate easily and propel your business forward with our dynamic cloud solutions.</p>    
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Data Storage and Management</h3>
                           <p>Boost the Quality of data management with our Cloud Solutions, with secure, scalable, and accessible storage. Employ advanced dashboards to empower your business and exceed the value of all its data assets. With our extensive cloud solutions, you'll securely store and manage data while propelling the plan to new heights.</p> 
                           </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Advanced Analytics</h3>
                            <p>Foster collaboration and data-driven decisions with our Collaborative Platforms and Advanced Analytics solutions. Utilize cloud-based tools for real-time communication, seamless collaboration, and harnessing data insights. Drive strategic business growth through our streamlined and powerful solutions.</p>
                            </div>
                    </div>
                    
                </div>
               
            </div>
        </section>


        <Gotaproject />

        <section className='transform-your-business '>
            <div className='container-fluid'>
                <div className='business-heading'>
                    <h2><span>Key Benefits</span> of Cloud Solutions</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <span><img src="image/s1.png" alt="" /></span>
                            <h3>Scalability and Flexibility</h3>
                            <p>Unlock business potential with our cloud solutions, which provide straightforward adaptability and unparalleled flexibility. Adapt without difficulty to changing demands, enhancing accomplishment as well as resources for long-term growth.</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/s2.png" alt="" /></span>
                            <h3>Cost-Efficiency</h3>
                         <p>Revolutionise your cost model by leveraging our cloud solutions, eliminating the need for extensive physical infrastructure. Pay only for the resources you consume, optimising operational costs and allowing your business to allocate resources more strategically.
</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content pd-right'>
                        <span><img src="image/s3.png" alt="" /></span>
                            <h3>Enhanced Collaboration</h3>
                           <p>Encourage a culture of mutually beneficial cooperation among teams that crosses borders of borders. With shared and real-time access to cloud-based data and applications, your teams are able to collaborate with greater efficiency, resulting in boosted productivity and innovation.</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/s4.png" alt="" /></span>
                            <h3>Data Security and Compliance</h3>
                           <p>Entrust your sensitive data to our cloud solutions, fortified by robust security measures and compliance features. We prioritise the confidentiality and integrity of your information, ensuring adherence to the highest industry standards & regulatory requirements.

</p>
                           
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/s5.png" alt="" /></span>
                            <h3>Business Continuity</h3>
                           <p>Fortify your operations with our cloud solutions' built-in redundancy and disaster recovery capabilities. Safeguard against unforeseen events and maintain business continuity, providing uninterrupted services to your clients and stakeholders.</p>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content pd-right'>
                        <span><img src="image/s6.png" alt="" /></span>
                            <h3>Innovation Acceleration</h3>
                         <p>Propel your business into the future by embracing rapid innovation. With easy access to cutting-edge technologies through our cloud solutions, stay ahead in a dynamic and competitive landscape, positioning your business for sustained success.
</p>
                        </div>
                    </div>
                </div>
               
            </div>
        </section>

<section className='custom-faq'>
    <div className='container-fluid'>
        <div className='faq-inner'>
        <div className="accordion">
    <h1>Frequently Asked Questions</h1>
    <div className="accordion-item">
        <input type="checkbox" id="accordion1" />
        <label for="accordion1" className="accordion-item-title"><span className="icon"></span> How secure is my data in the cloud?</label>
        <div className="accordion-item-desc">We prioritize data security with robust measures, including encryption and compliance features, ensuring the confidentiality and integrity of your information.
</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion2" />
        <label for="accordion2" className="accordion-item-title"><span className="icon"></span>Can I customize my cloud infrastructure as per my business needs?</label>
        <div className="accordion-item-desc">Absolutely, our cloud solutions are designed for scalability and flexibility, allowing you to tailor the infrastructure to meet the specific requirements of your business.

</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion3" />
        <label for="accordion3" className="accordion-item-title"><span className="icon"></span>How does the cloud help with disaster recovery and business continuity?</label>
        <div className="accordion-item-desc"> Our cloud solutions include built-in redundancy and disaster recovery features, ensuring your business operations remain uninterrupted even in the face of unforeseen events.


</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion4" />
        <label for="accordion4" className="accordion-item-title"><span className="icon"></span> What cost savings can I expect by moving to the cloud?</label>
        <div className="accordion-item-desc"> Cloud solutions offer cost-efficiency by eliminating the need for extensive physical infrastructure, allowing you to pay only for the resources you consume, optimizing operational costs.


</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion5" />
        <label for="accordion5" className="accordion-item-title"><span className="icon"></span>How can the cloud enhance collaboration among my remote teams?</label>
        <div className="accordion-item-desc">  Cloud solutions facilitate seamless collaboration by providing shared and real-time access to data and applications, fostering efficient teamwork across geographical locations.
</div>
    </div>

</div>
        </div>
    </div>
</section>

    </div>
    </div>
    </div>
  )
}
