import React from 'react'
import { Link } from "react-router-dom";
export default function TaskManagementSystem() {
  return (
    <div>
           <div>
        <div className='custom-banner'>
        <div className='container-fluid'>
        <div className='banner-content'>
                <h2>Task Management System</h2>
               <p>Fed up with dealing with sticky notes and forgetting tasks? We understand. That's why we created Amsys Task Management System, the next-gen task manager. Our smart daily planner makes handling your to-do list online a breeze. Need to share tasks? No problem! Collaborate effortlessly and simplify task management. Bid farewell to chaos and welcome increased productivity and peace of mind.</p>
                <Link to="/bookfreeconsultation">Book Free Consultation</Link>
            </div>
        </div>
            <img className='bnr-height' src="image/software.png" alt="" />
        </div>

       <section className='custom-work-details-main'>
          <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-3'>
                    <div className='work-sidebar'>
                       <div className='work-inner'>
                        <h6>Client</h6>
                        <h3>Aforserve.com Limited</h3>
                       </div>
                       <div className='work-inner'>
                        <h6>Industry</h6>
                        <h3>Education</h3>
                       </div>
                       <div className='work-inner'>
                        <h6>Service</h6>
                        <h3>UI/UX Design</h3>
                       </div>
                       <div className='work-inner'>
                        <h6>Team Setup</h6>
                        <h3>1 Researcher + 1 Designer</h3>
                       </div>
                       <div className='work-inner'>
                        <h6>Timeline</h6>
                        <h3>9 to 10 Months</h3>
                       </div>
                    </div>
                </div>
                <div className='col-lg-9'>
                    <div className='work-details-right'>
                      <p>Amsys Task Management System goes beyond just personal organization; it's a collaborative tool that allows you to share tasks effortlessly. Whether you're working on a team project or coordinating household responsibilities, our system facilitates seamless collaboration. With features designed for easy task sharing and updates, you can ensure that everyone is on the same page, contributing to a more efficient and well-coordinated workflow. Embrace the future of task management with Amsys, where the days of sticky notes and forgotten tasks are replaced by a streamlined, collaborative, and stress-free approach to productivity.</p>
                        <h4>Key Features </h4>
                        <ul>
                            <li>Intuitive Interface</li>
                            <li>Task Organization</li>
                            <li>Smart Reminders</li>
                            <li>Collaboration Tools</li>
                            <li>Real-time Updates</li>
                            <li>File Attachments</li>
                            <li>Deadline Tracking</li>
                            <li>Progress Tracking</li>
                            <li>Mobile Accessibility</li>
                            <li>Integration Options</li>
                            <li>Customizable Views</li>
                            <li>Search and Filter</li>
                            <li>Offline Functionality</li>
                            <li>Data Security</li>
                            <li>Analytics and Reports</li>
                        </ul>
                    </div>
                </div>
            </div>
          </div>
       </section>


        {/* <Gotaproject /> */}

  

    </div>
    </div>
  )
}
