import React from 'react';
import './App.css';
import './menu.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
  useLocation,
} from "react-router-dom";
import Footer from './components/Footer.js';
// import Header from './components/Header.js';
// import Slider from './components/Slider.js';
import Services from './components/Services.js';
import Form from './components/Form.js';
import Ourwork from './components/Ourwork.js';
import About from './Pages/About.js';
import Home from './Pages/Home.js';
import Softwaredevelopment from './components/Softwaredevelopment.js';
import Mobileappdevelopment from './components/Mobileappdevelopment.js';
import CloudSolutions from './components/CloudSolutions.js';
import Cybersecurity from './components/Cybersecurity.js';
import Datacentersolutions from './components/Datacentersolutions.js';
import ERPsolutions from './components/ERPsolutions.js';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Whatwedo from './components/Whatwedo.js';
import HeaderMenu from './components/HeaderMenu.js';
import BookFreeConsultation from './components/BookFreeConsultation.js';
import ContactForm from './components/ContactForm.js';
import Tabs from './components/Tabs.js';
import ARMS from './Pages/ARMS.js';
import XRMS from './Pages/XRMS.js';
import SOC from './Pages/SOC.js';
import Sellncash from './Pages/Sellncash.js';
import BuyBackGuarantee from './Pages/BuyBackGuarantee.js';
import HRMS from './Pages/HRMS.js';
import IMS from './Pages/IMS.js';
import FacultyFeedback from './Pages/FacultyFeedback.js';
import TaskManagementSystem from './Pages/TaskManagementSystem.js';
import VideoConferencingTool from './Pages/VideoConferencingTool.js';
import AssetManagementSystem from './Pages/AssetManagementSystem.js';
import ShimlaSmartCity from './Pages/ShimlaSmartCity.js';
import Testing from './Pages/Testing.js';
import Career from './components/Career.js';
import CareerDetails from './components/CareerDetails.js';

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div>   
     <Router>
      <HeaderMenu />
      <ScrollToTop />
      {/* <ContactForm /> */}
     {/* <Header/> */}
          <Routes  history={HashRouter}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/form" element={<Form />} />
            <Route exact path="/ourwork" element={<Ourwork />} />
            <Route exact path="/softwaredevelopment" element={<Softwaredevelopment />} />
            <Route exact path="/mobileappdevelopment" element={<Mobileappdevelopment />} />
            <Route exact path="/cloudsolutions" element={<CloudSolutions />} />
            <Route exact path="/cybersecurity" element={<Cybersecurity />} />
            <Route exact path="/datacentersolutions" element={<Datacentersolutions />} />
            <Route exact path="/erpsolutions" element={<ERPsolutions />} />
            <Route exact path="/whatwedo" element={<Whatwedo />} />
            <Route exact path="/bookfreeconsultation" element={<BookFreeConsultation />} />
            <Route exact path="/contactform" element={<ContactForm />} />
            <Route exact path="/tabs" element={<Tabs />} />
            <Route exact path="/arms" element={<ARMS />} />
            <Route exact path="/xrms" element={<XRMS />} />
            <Route exact path="/soc" element={<SOC />} />
            <Route exact path="/sellphone" element={<Sellncash />} />
            <Route exact path="/buybackguarantee" element={<BuyBackGuarantee />} />
            <Route exact path="/hrms" element={<HRMS />} />
            <Route exact path="/ims" element={<IMS />} />
            <Route exact path="/facultyfeedback" element={<FacultyFeedback />} />
            <Route exact path="/taskmanagementsystem" element={<TaskManagementSystem />} />
            <Route exact path="/videoconferencingtool" element={<VideoConferencingTool />} />
            <Route exact path="/assetmanagementsystem" element={<AssetManagementSystem />} />
            <Route exact path="/shimlasmartcity" element={<ShimlaSmartCity />} />
            <Route exact path="/career" element={<Career />} />
            <Route exact path="/careerdetails" element={<CareerDetails />} />
          </Routes>
          <Footer/>
      </Router>

    </div>
  );
}

export default App;
