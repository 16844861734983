import React from 'react'
import { Link } from "react-router-dom";

export default function HRMS() {
  return (
    <div>
          <div>
        <div className='custom-banner'>
        <div className='container-fluid'>
        <div className='banner-content'>
                <h2>Human Resources Management System</h2>
                <p>Amsys' HRMS stands as an all-encompassing solution, efficiently overseeing and executing various HR components within a streamlined system that is both swift and economical for an organization. A unified and integrated system proficiently manages the entirety of HR practices, including recruitment processes, employee information, performance appraisals, payroll procedures, generation of salary slips, comprehensive bank statement submissions, as well as handling aspects such as PF, loans, ESI, and conveyance in accordance with vouchers.</p>
                
                <Link to="/bookfreeconsultation">Book Free Consultation</Link>
            </div>
        </div>
            <img className='bnr-height' src="image/software.png" alt="" />
        </div>

       <section className='custom-work-details-main'>
          <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-3'>
                    <div className='work-sidebar'>
                       <div className='work-inner'>
                        <h6>Client</h6>
                        <h3>Aforserve.com Limited</h3>
                       </div>
                       <div className='work-inner'>
                        <h6>Industry</h6>
                        <h3>Education</h3>
                       </div>
                       <div className='work-inner'>
                        <h6>Service</h6>
                        <h3>UI/UX Design</h3>
                       </div>
                       <div className='work-inner'>
                        <h6>Team Setup</h6>
                        <h3>1 Researcher + 1 Designer</h3>
                       </div>
                       <div className='work-inner'>
                        <h6>Timeline</h6>
                        <h3>9 to 10 Months</h3>
                       </div>
                    </div>
                </div>
                <div className='col-lg-9'>
                    <div className='work-details-right'>

                        <p>In the realm of HR management, Amsys' solution not only provides efficiency but also fosters accuracy and compliance. With a user-friendly interface and advanced functionalities, our HRMS empowers organizations to navigate the complexities of HR operations with ease. Whether it's ensuring compliance with statutory requirements or streamlining financial processes related to employee compensation, our HRMS is a reliable and adaptable tool. Elevate your HR practices with Amsys' HRMS, where efficiency meets economy, creating a harmonious blend that caters to the diverse needs of your organization while ensuring a seamless and cost-effective HR management experience.</p>

                        <h4>Key Features </h4>
                        <ul>
                        <li>Talent Acquisition</li>
                        <li>Interview Assessment</li>
                        <li>Payroll Processing</li>
                        <li>Attendance & Leave Administration</li>
                        <li>Performance Appraisals</li>
                        <li>Taxation Management</li>
                        <li>GPF (General Provident Fund) </li>
                        <li>Exit Formalities</li>
                    </ul>


                    </div>
                </div>
            </div>
          </div>
       </section>


        {/* <Gotaproject /> */}

  

    </div>
    </div>
  )
}
