import React, { useState }  from 'react';
import { Link,NavLink  } from "react-router-dom";

const MenuItem = [
    {
        SubMenu: false,
        MenuItemName:"Home",
        MenuItemLink:"/",
        ChildMenu:[],
    },
    {
        SubMenu:false,
        MenuItemName:"About us",
        MenuItemLink:"/about",
        ChildMenu:[
            {
                SubMenu: false,
                MenuItemName: "About1",
                MenuItemLInk: "/about",
              },
              {
                SubMenu: false,
                MenuItemName: "About2",
                MenuItemLInk: "/about",
              },
              {
                SubMenu: false,
                MenuItemName: "About3",
                MenuItemLInk: "/about",
              },
        ],
    },

    {
        SubMenu: false,
        MenuItemName:"Our Works",
        MenuItemLink:"/ourwork",
        ChildMenu:[],
    },
    {
        SubMenu:true,
        MenuItemName:"Services",
        MenuItemLink:"",
        ChildMenu:[
            {
                SubMenu:false,
                MenuItemName:"Software Development",
                MenuItemLink:"/softwaredevelopment",
                MenuItemIcon:"image/menu1.png",
                ChildMenuInner:[
                    {
                        SubMenu:false,
                        MenuItemName:"About",
                        MenuItemLink:"about",
                    },
                    {
                        SubMenu:false,
                        MenuItemName:"Mission",
                        MenuItemLink:"/mission",
                    },
                    {
                        SubMenu:false,
                        MenuItemName:"Community",
                        MenuItemLink:"/community",
                    },
                    {
                        SubMenu:false,
                        MenuItemName:"Help",
                        MenuItemLink:"/help",
                    },
                ],
            },
            {
                SubMenu:false,
                MenuItemName:"Mobile App Development",
                MenuItemLink:"/Mobileappdevelopment",
                MenuItemIcon:"image/menu2.png",
                ChildMenuInner:[
                    {
                        SubMenu:false,
                        MenuItemName:"News",
                        MenuItemLink:"/news",
                    },
                    {
                        SubMenu:false,
                        MenuItemName:"Events",
                        MenuItemLink:"/events",
                    },
                    {
                        SubMenu:false,
                        MenuItemName:"Blog",
                        MenuItemLink:"/blog",
                    },
                ],
            },
            {
                SubMenu:false,
                MenuItemName:"Cloud Solutions",
                MenuItemLink:"/CloudSolutions",
                MenuItemIcon:"image/menu3.png",
                ChildMenuInner:[
                    {
                        SubMenu:false,
                        MenuItemName:"New Opportunities",
                        MenuItemLink:"/opportunities",
                    },
                    {
                        SubMenu:false,
                        MenuItemName:"Life @ Company",
                        MenuItemLink:"/lifeCompany",
                    },
                    {
                        SubMenu:false,
                        MenuItemName:"Why Join Us?",
                        MenuItemLink:"/whyJoinUs",
                    },
                ],
            },
            {
                SubMenu:false,
                MenuItemName:"Cyber Security",
                MenuItemLink:"/cybersecurity",
                MenuItemIcon:"image/menu4.png",
                ChildMenuInner:[
                    {
                        SubMenu:false,
                        MenuItemName:"Return Policy",
                        MenuItemLink:"/returnPolicy",
                    },
                    {
                        SubMenu:false,
                        MenuItemName:"Privecy Policy",
                        MenuItemLink:"/privecyPolicy",
                    },
                    {
                        SubMenu:false,
                        MenuItemName:"Terms & Conditions",
                        MenuItemLink:"/termsConditions",
                    },
                ],
            },
            {
                SubMenu:false,
                MenuItemName:"Data Center Solutions",
                MenuItemLink:"/datacentersolutions",
                MenuItemIcon:"image/menu5.png",
                ChildMenuInner:[
                    {
                        SubMenu:false,
                        MenuItemName:"Return Policy",
                        MenuItemLink:"/returnPolicy",
                    },
                  
                ],
            },

            {
                SubMenu:false,
                MenuItemName:"ERP Solutions",
                MenuItemLink:"/erpsolutions",
                MenuItemIcon:"image/menu6.png",
                ChildMenuInner:[
                    {
                        SubMenu:false,
                        MenuItemName:"Return Policy",
                        MenuItemLink:"/returnPolicy",
                    },
                  
                ],
            },
        ],
    },
    {
        SubMenu: false,
        MenuItemName:"What we do",
        MenuItemLink:"/whatwedo",
        ChildMenu:[],
    },
    {
        SubMenu: false,
        MenuItemName:"Career",
        MenuItemLink:"/career",
        ChildMenu:[],
    }
    
    // {
    //     SubMenu:false,
    //     MenuItemName:"Contact us",
    //     MenuItemLink:"contact",
    //     ChildMenu:[],
    // }
];

const HeaderMenu = () => {
  const [isOpen, setIsopen] = useState(false);
  const ToggleSidebar = () => {
      isOpen === true ? setIsopen(false) : setIsopen(true);
      setisActive(false);
      setaddActive(false);
  };

  const [isActive, setisActive] = useState(false);
  const OpenMenuActive = (ind) => {
    if (isActive === ind) {
      return setisActive(null);
    }
    setisActive(ind);
  };

  const [addActive, setaddActive] = useState(false);
  const OpenAddActive = (childInd) => {
    if (addActive === childInd) {
      return setaddActive(null);
    }
    setaddActive(childInd);
  };

  return (
    <header className='main-header'>
        <div className='main-header-container'>
            <div className='header-logo'>
            <Link to="/">
                <img src="logo-n.png" alt="Logo" />
            </Link> 
            </div>    
            <div className="mobile-trigger">
                <span className='triggerbtn' onClick={ToggleSidebar}><em><img src="image/menu.png" alt="" /></em></span>
            </div>
            <div className={`custom-menu-wrapper ${isOpen == true ? 'menu-open' : ''}`}>
                <ul className="custom-menu-primary">
                    {
                    MenuItem.map((menuVal, Ind) => {
                        return (
                        <li className={`menu-item ${menuVal.SubMenu ? "has-submenu" : ""}`} key={Ind}>
                            {
                            menuVal.SubMenu ? <a className={`${isActive === Ind ? "active" : ""}`} onClick={() => OpenMenuActive(Ind)}>{menuVal.MenuItemName}</a> 
                            : <NavLink to={ menuVal.MenuItemLink }>{menuVal.MenuItemName}</NavLink>
                            }
                            
                            {menuVal.SubMenu ?
                            <ul className={`childmenu-wrapper ${isActive === Ind ? "active" : ""}`}>
                        
                                {
                                menuVal.ChildMenu.map((childVal, childInd) => {
                                    return (
                                    <li className={`menu-item ${childVal.SubMenu ? "has-submenu" : ""}`} key={childInd}>
                                        { childVal.SubMenu ? <a className={`${addActive === childInd ? "active" : ""}`} onClick={() => OpenAddActive(childInd)}>{childVal.MenuItemName}</a>
                                        : <NavLink to={ childVal.MenuItemLink }>{childVal.MenuItemIcon?<img src={childVal.MenuItemIcon}></img>:''}{childVal.MenuItemName}</NavLink>}
                                        {childVal.SubMenu ?
                                        <ul className={`childmenu-wrapper ${addActive === childInd ? "active" : ""}`}>
                                        {
                                            childVal.ChildMenuInner.map((cInnerVal, cInnerInd)=>{
                                            return(
                                                <li className={`menu-item`} key={cInnerInd}><Link to={cInnerVal.MenuItemLink}>{cInnerVal.MenuItemName}</Link></li>
                                            )
                                            })
                                        }
                                        </ul>
                                        : ""}
                                    </li>
                                    )
                                })
                                }
                        
                            </ul>
                            : ""}
                        </li>
                        
                        )
                    })
                    }
                    <li className="login-btn-primary">
                   <Link to="/bookfreeconsultation">Get in touch</Link>
          </li>
                </ul>
            </div>
        </div>
    </header>
  )
}

export default HeaderMenu;