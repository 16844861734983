import React from 'react'
import { Link } from "react-router-dom";
export default function BuyBackGuarantee() {
  return (
    <div>
        <div>
        <div className='custom-banner'>
        <div className='container-fluid'>
        <div className='banner-content'>
                <h2>BuyBack Guarantee</h2>
               <p>Revolutionize the landscape of gadget resale with Retech, a cutting-edge software solution that effortlessly integrates into your sales platform. We bring a unique approach by introducing guaranteed buyback coupons to your customers, creating a win-win scenario for both buyers and sellers. When customers purchase a new or refurbished device through your platform, they can now lock in a future resale price with Retech, ensuring value retention for a period of 6 months or 1 year. This innovative feature not only sets your platform apart but also instills a sense of trust and reliability, providing customers with a tangible incentive for their future gadget upgrades.</p>
                <Link to="/bookfreeconsultation">Book Free Consultation</Link>
            </div>
        </div>
            <img className='bnr-height' src="image/software.png" alt="" />
        </div>

       <section className='custom-work-details-main'>
          <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-3'>
                    <div className='work-sidebar'>
                       <div className='work-inner'>
                        <h6>Client</h6>
                        <h3>Aforserve.com Limited</h3>
                       </div>
                       <div className='work-inner'>
                        <h6>Industry</h6>
                        <h3>Education</h3>
                       </div>
                       <div className='work-inner'>
                        <h6>Service</h6>
                        <h3>UI/UX Design</h3>
                       </div>
                       <div className='work-inner'>
                        <h6>Team Setup</h6>
                        <h3>1 Researcher + 1 Designer</h3>
                       </div>
                       <div className='work-inner'>
                        <h6>Timeline</h6>
                        <h3>9 to 10 Months</h3>
                       </div>
                    </div>
                </div>
                <div className='col-lg-9'>
                    <div className='work-details-right'>
                        <p>Retech is more than just a software; it's a catalyst for transforming customer engagement and propelling your sales to unprecedented heights. By offering guaranteed buyback coupons, Retech creates a unique selling proposition that fosters long-term relationships with your customer base. This forward-thinking approach not only encourages repeat business but also positions your platform as a trustworthy partner in the ever-evolving world of gadget sales. Elevate your resale platform with Retech, where trust, engagement, and future value are seamlessly integrated into every transaction.</p>
                        
                        <h4>Key Features </h4>
                        <ul>
                            <li>Guaranteed Buyback Coupons</li>
                            <li>Seamless Integration</li>
                            <li>Flexible Resale Duration</li>
                            <li>New and Refurbished Device Options</li>
                            <li>Empowered Trust</li>
                            <li>Enhanced Customer Engagement</li>
                            <li>Sales Propulsion</li>
                            <li>User-Friendly Interface</li>
                            <li>Automated Resale Price Lock-In</li>
                            <li>Extended Resale Validity</li>
                            <li>Data Security Measures</li>
                            <li>Comprehensive Device Evaluation</li>
                            <li>Marketing Support</li>
                            <li>Sustainability Focus</li>
                            <li>Dynamic Pricing Models</li>
                      </ul>

                    </div>
                </div>
            </div>
          </div>
       </section>


        {/* <Gotaproject /> */}

  

    </div>
    </div>
  )
}
