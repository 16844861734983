import React from 'react'
import Slickslider from './Slickslider';
import { Link } from "react-router-dom";

function Slider() {
  return (
    <div>

<Slickslider />

        {/* <div className='custom-banner'>
        <div className='container-fluid'>
        <div className='banner-content'>
                <h2>Software Development</h2>
                <p>Extend your digital presence seamlessly to both mobile and web platforms by leveraging the latest trends and cutting-edge technologies.</p>
                <a>Get Started</a>
                <div className='banner-cons'>
                    <img src="image/banner-icon.png" alt="" />
                </div>
            </div>
        </div>
            <img className='bnr-height' src="image/banner.png" alt="" />
        </div> */}

        <section className='transform-your-business'>
            <div className='container-fluid'>
                <div className='business-heading'>
                    <h2>Transform your <span>Business</span> with the services we offer.</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <span><img src="image/1.svg" alt="" /></span>
                            <h3>Software Development</h3>
                            <ul>
                            <li>Breathe life into your ideas with our versatile software development, crafting innovative solutions that exceed expectations.</li>
            <li>Bridge the gap between vision and reality with our expert craftsmanship, delivering robust, scalable software tailored to your needs.</li>
            <li>Fuel efficiency and dexterity with cutting-edge technologies and methodologies, ensuring a competitive edge in today's dynamic landscape.</li>
            <li>Foster seamless collaboration and transparent communication, guaranteeing a successful journey from concept to deployment.</li>
        
                            </ul>
                            <Link to="/softwaredevelopment">Learn More</Link>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/2.svg" alt="" /></span>
                            <h3>Cloud Solutions</h3>
                            <ul>
                            <li>Unleash limitless potential with our scalable cloud solutions, empowering agility, innovation, and cost-effective growth.</li>
            <li>Simplify your IT landscape with secure, reliable cloud infrastructure, eliminating complexity and boosting operational efficiency.</li>
            <li>Harness the power of data-driven insights with our advanced cloud analytics, fueling smarter decision-making and competitive advantage.</li>
            <li>Elevate your security posture with industry-leading cloud security measures, ensuring peace of mind and data protection.</li>
                            </ul>
                            <Link to="/CloudSolutions">Learn More</Link>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content pd-right'>
                        <span><img src="image/3.svg" alt="" /></span>
                            <h3>Cyber Security</h3>
                            <ul>
                            <li>Fortress your defenses with comprehensive cybersecurity solutions, safeguarding your data and ensuring digital resilience.</li>
            <li>Proactively combat evolving threats with our expert threat intelligence and incident response, minimizing downtime and maximizing security.</li>
            <li>Empower your team with security awareness training and tailored solutions, building a culture of cyber vigilance.</li>
            <li>Navigate the compliance landscape with confidence, leveraging our expertise to meet industry standards and regulations.</li>
                            </ul>
                            <Link to="/cybersecurity">Learn More</Link>
                            
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/4.svg" alt="" /></span>
                            <h3>ERP Solution</h3>
                            <ul>
                            <li>Unify your operations with a streamlined ERP, unlocking data-driven insights for smarter decision-making.</li>
            <li>Automate workflows, boost efficiency, and eliminate silos with a centralized platform for all your core business processes.</li>
            <li>Gain real-time visibility and control over your entire organization, from finance and inventory to production and customer service.</li>
            <li>Scale effortlessly, future-proof your growth, and drive bottom-line results with a flexible and adaptable ERP solution.</li>
                            </ul>
                            <Link to="/erpsolutions">Learn More</Link>
                           
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/5.svg" alt="" /></span>
                            <h3>Mobile App Development</h3>
                            <ul>
                            <li>Craft captivating mobile apps that ignite engagement, fueled by our expert development and design mastery.</li>
            <li>Streamline your workflow, boost productivity, and empower your users with custom mobile solutions tailored to your unique needs.</li>
            <li>Reach new audiences and unlock untapped potential with future-proof apps optimized for all platforms and devices.</li>
            <li>Collaborate with our agile team to transform your vision into reality, ensuring a seamless journey from concept to launch.</li>
        
                            </ul>

                            <Link to="/Mobileappdevelopment">Learn More</Link>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content pd-right'>
                        <span><img src="image/6.svg" alt="" /></span>
                            <h3>Data Center Solutions</h3>
                            <ul>
                            <li>Fortify your digital foundation with secure, scalable data center solutions built for peak performance and unwavering reliability.</li>
            <li>Optimize efficiency and costs with expert infrastructure management, ensuring seamless operations and maximum uptime.</li>
            <li>Safeguard your critical data with robust security measures and proactive threat mitigation, enabling complete peace of mind.</li>
            <li>Navigate your hybrid cloud journey with seamless integration and migration services, empowering agility and innovation.</li>
                            </ul>

                            <Link to="/datacentersolutions">Learn More</Link>

                        </div>
                    </div>
                </div>
               
            </div>
        </section>
        <section className='custom-who-we-are'>
        <div className='who-we-are'>
                    <div className='who-we-are-img'>
                        <img src="image/who-we-are.png" alt="" />
                    </div>
                    <div className='who-we-are-inner'>
                        <h6>WHO WE ARE</h6>
<h3>We build greater futures through innovation and collective knowledge.</h3>
<p>Amsys Infocom Pvt. Ltd. was established in 2016 with the vision to provide cost effective IT solutions in market to bridge the gap between IT needs & affordability. Amsys is managed by highly professional and experienced team focusing on fulfilling the technical and IT needs of major government and corporate clients.</p>
                    <br />
                    <Link to="/about">Get to know us</Link>
                    </div>
                </div>
        </section>

        <section className='custom-what-we-do'>
               <div className='what-we-do'>
               
                    <div className='who-we-are-inner'>
                        <h6>WHAT WE DO</h6>
<h3>Amsys transforms businesses with smart technology solutions</h3>
<p>Amsys Infocom excels in custom software development, cloud solutions, cybersecurity, and server optimisation. Committed to excellence, we provide tailored solutions for unique business needs, ensuring robust data protection and enhanced user experiences.</p>
                    <br />
                    <Link to="/whatwedo">Get to know us</Link>
                    </div>

                    <div className='what-we-do-img'>
                        <img src="image/who-we-are2.png" alt="" />
                    </div>
                </div>
        </section>


        <section className='custom-trusted-main'>
            <div className='container-fluid'>
                <div className='custom-heading-trusted'>
                    <h2>Trusted by World’s Leading Companies</h2>
                </div>
                <div className='row custom-logo-mobile'>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo1.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo2.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo3.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo4.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo5.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo6.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo7.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo8.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo9.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo10.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo11.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo12.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo13.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo14.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo15.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo16.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo17.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo18.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo19.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo20.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/logo21.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="testimonial text-center">
  <div className="container">
    <div className="heading white-heading">Testimonials & Clients</div>
    <div
      id="testimonial4"
      className="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x"
      data-ride="carousel"
      data-pause="hover"
      data-interval={5000}
      data-duration={2000}
    >
      <div className="carousel-inner" role="listbox">
        <div className="carousel-item active">
          <div className="testimonial4_slide">
           
            <p>
            I can affirm that Aforserve.com Limited has reached a significant milestone in its development and is now fully operational. This achievement is attributed to the commendable efforts and contributions of Amsys Infocom Pvt Ltd. Their expertise and dedication have played a pivotal role in ensuring the substantial completion and successful functionality of the project. 
            </p>
            <h4> Arunabha Das </h4>
                <p>Managing Director</p>
          </div>
        </div>
        <div className="carousel-item">
          <div className="testimonial4_slide">
           
            <p>
            I confirm, to the best of my understanding and after thoroughly inspecting, observing, and testing the project, that the Shimla Smart City Website has been substantially finished and is functional, courtesy of Amsys Infocom Pvt Ltd. The completion of the Shimla Smart City Website adheres to the guidelines provided by the department.
            </p>
            <h4>Andraiz Ahmed </h4>
            <p>Project Manager</p>
          </div>
        </div>
       
      </div>

    
      <a
        className="carousel-control-prev"
        href="#testimonial4"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" />
      </a>
      <a
        className="carousel-control-next"
        href="#testimonial4"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" />
      </a>
    </div>
  </div>
  {/* <div className="marquee">
         <p> 
           <div className="content img-marquee">
              <img src="image/c1.png" alt="" />
            </div>
        </p>
        <p> 
           <div className="content img-marquee">
              <img src="image/c2.png" alt="" />
            </div>
        </p>
        
        <p> 
           <div className="content img-marquee">
              <img src="image/c3.png" alt="" />
            </div>
        </p>
        <p> 
           <div className="content img-marquee">
              <img src="image/c4.png" alt="" />
            </div>
        </p>
        <p> 
           <div className="content img-marquee">
              <img src="image/c5.png" alt="" />
            </div>
        </p>
        <p> 
           <div className="content img-marquee">
              <img src="image/c1.png" alt="" />
            </div>
        </p>
        <p> 
           <div className="content img-marquee">
              <img src="image/c2.png" alt="" />
            </div>
        </p>
        
        <p> 
           <div className="content img-marquee">
              <img src="image/c3.png" alt="" />
            </div>
        </p>
        <p> 
           <div className="content img-marquee">
              <img src="image/c4.png" alt="" />
            </div>
        </p>
        <p> 
           <div className="content img-marquee">
              <img src="image/c5.png" alt="" />
            </div>
        </p>
</div> */}

<div className="marquee-wrapper">
	<div className="container-fluid">
		{/* <div className="marquee-block">
			<div className="marquee-inner to-left">
				<span>
					<div className="marquee-item">
                    <img src="image/c1.png" alt="" />
					</div>
					<div className="marquee-item">
                    <img src="image/c2.png" alt="" />
					</div>
					<div className="marquee-item">
                       <img src="image/c3.png" alt="" />
					</div>
					<div className="marquee-item">
                    <img src="image/c4.png" alt="" />
					</div>
					<div className="marquee-item">
                    <img src="image/c5.png" alt="" />
					</div>
                    <div className="marquee-item">
                    <img src="image/c6.png" alt="" />
					</div>
					<div className="marquee-item">
                    <img src="image/c7.png" alt="" />
					</div>

                    <div className="marquee-item">
                       <img src="image/c8.png" alt="" />
					</div>
					
                    <div className="marquee-item">
                    <img src="image/c9.png" alt="" />
					</div>
                    <div className="marquee-item">
                    <img src="image/c10.png" alt="" />
					</div>
					
				</span>
				<span>
               
                    <div className="marquee-item">
                    <img src="image/c11.png" alt="" />
					</div>
                    <div className="marquee-item">
                       <img src="image/c14.png" alt="" />
					</div>
                    <div className="marquee-item">
                    <img src="image/c12.png" alt="" />
					</div>
                    <div className="marquee-item">
                    <img src="image/c13.png" alt="" />
					</div>

                   
					
                    <div className="marquee-item">
                    <img src="image/c15.png" alt="" />
					</div>
                    <div className="marquee-item">
                    <img src="image/c16.png" alt="" />
					</div>
                    <div className="marquee-item">
                    <img src="image/c17.png" alt="" />
					</div>
				</span>
			</div>
		</div> */}
            
            <div class="position-relative marquee-container d-none d-sm-block">
  <div class="marquee d-flex justify-content-around">
    <span>
        <img src="image/ff.png" alt="" />
    </span>
  </div>
  <div class="marquee marquee2 d-flex justify-content-around">
    <span>
        <img src="image/ff2.png" alt="" />
    </span>
  </div>
</div>

	</div>
</div>
</section>


    <section className='custom-trusted-main we-offer'>
            <div className='container-fluid'>
                <div className='custom-heading-trusted'>
                    <h2>Technologies we offer</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer1.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer2.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer3.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer4.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer5.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer6.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer7.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer8.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer9.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer10.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer11.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer12.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer13.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer14.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer15.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer16.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer17.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer18.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer19.png" alt="" />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <div className='logo-img'>
                            <img src="image/offer20.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
  )
}

export default Slider;