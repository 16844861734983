import React from 'react'
import { Link } from "react-router-dom";

export default function CareerDetails() {
  return (
    <div>
        <div className='custom-career-details'>
            <div className='container-fluid'>
                <h4> IT Sales Representative <span>at</span> Amsys Infocom</h4>
                <p>We are seeking an IT Sales Representative who can join our Dynamic Team Share your CV at hr@amsysinfo.com </p>
            </div>
        </div>

        <div className='custom-career-details-inner'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-8'>
                        <div className='job-description'>
                            <h4>IT Sales Representative (Domestic)</h4>
                            <p>Job Types: Full-time, Permanent</p>
                            <h5>Job Summary:</h5>
                            <p>We're in search of a dynamic IT Sales Representative for our software company. This role requires 2-3 years of relevant experience, strong client interaction skills, and a track record of bringing invaluable business opportunities. Fluency in English, a Bachelor's degree, and an MBA in marketing and IT are preferred qualifications.</p>
                            <h5>Key Responsibilities:</h5>
                            <ul>
                                <li> Client Interaction: Build strong client relationships by understanding their needs and offering tailored IT solutions.</li>
                                <li>Business Growth: Generate new opportunities through research, networking, and lead generation.</li>
                                <li>Sales Strategy: Develop and execute effective strategies to meet sales targets and expand the client base.</li>
                                <li>Market Analysis: Stay updated on industry trends and competitors to identify growth areas.</li>
                                <li>Proposal Preparation: Create compelling proposals showcasing our IT solutions.</li>
                                <li>Negotiation: Skillfully negotiate contracts to secure favourable terms.</li>
                                <li>Client Retention: Ensure satisfaction and retention through exceptional support.</li>
                                <li>Team Collaboration: Work closely with sales and technical teams to deliver high-quality solutions.</li>
                            </ul>
                            <h5>Qualifications:</h5>
                            <ul>
                                <li>Bachelor's degree, MBA preferred with a focus on Marketing and IT.</li>
                                <li>2-3 years of IT software industry business development experience.</li>
                                <li>Proven track record of meeting or exceeding sales targets.</li>
                                <li>Excellent English communication skills.</li>
                            </ul>
                            <h5>Skills and Attributes:</h5>
                            <ul>
                                <li> Strong client-facing and interpersonal skills.</li>
                                <li>Strategic thinking and problem-solving abilities.</li>
                                <li> Self-motivated and results-driven.</li>
                                <li>Excellent negotiation and presentation skills.</li>
                                <li> Knowledgeable about IT software solutions and industry trends.</li>
                            </ul>
                            <h5>Additional Information:</h5>
                            <ul>
                                <li>Occasional travel may be required.</li>
                                <li>Strong market reputation and industry contacts preferred.</li>
                            </ul>
                            <p>If you're ready to drive business growth in the IT software industry, apply now and join our dynamic team.</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='career-form'>
                            <div className='looking-for job'>
                               <h3>Are you Looking for a Job?</h3>
                            </div>
                            <div className='apply-with-linkdin'>
                                <p>Apply for a job :</p>
                            <Link to="https://www.linkedin.com/jobs/view/3910552262/" target='_blank'><span><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      data-supported-dps="24x24"
      fill="currentColor"
      className="mercado-match"
      width="24"
      height="24"
      focusable="false"
    >
      <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>
    </svg></span> Apply with Linkdin</Link>
                            </div>
                            <div className='or'>
                                <span>or</span>
                            </div>
                        <form>
                            <div className='form-group'>
                                <label htmlFor='name'>Full Name</label>
                                <input type='text' id='name' name='name' required />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='email'>Email</label>
                                <input type='email' id='email' name='email' required />
                            
                            </div>
                            <div className='form-group'>
                                <label htmlFor='phone'>Mobile Number</label>
                                <input type='tel' id='phone' name='phone' required />
                            </div>

                            <div className='form-group'>
                                <label htmlFor='phone'>Mobile Number</label>
                                <input type='tel' id='phone' name='phone' required />
                            </div>

                            <div className='form-group'>
                                <label htmlFor='job'>In Which field you are you looking for a Job?</label>
                                <input type='text' id='job' name='job' required />
                            </div>

                            <div className='form-group'>
                                <label htmlFor='exp'>How much year of exp do you have?</label>
                                <input type='tel' id='exp' name='exp' required />
                            </div>

                            <div className='form-group'>
                                <label htmlFor='Notice'>What's your Notice Period?</label>
                                <input type='text' id='Notice' name='Notice' required />
                            </div>

                            <div className='form-group'>
                                <label htmlFor='CTC'>What's your CTC?</label>
                                <input type='tel' id='CTC' name='CTC' required />
                            </div>

                            <div className='form-group'>
                                <label htmlFor='CTC'>Expected CTC</label>
                                <input type='tel' id='CTC' name='CTC' required />
                            </div>

                            <div className='form-group'>
                                <label htmlFor='url'>LinkedIn URL</label>
                                <input type='text' id='url' name='url' required />
                            </div>

                            {/* <div className='form-group'>
                                <label htmlFor='msg'>Message</label>
                                <textarea type='text' id='msg' name='msg' required />
                            </div> */}

                            <br />
                            
                            <div className='form-group'>
                                <button type='submit' class="btn-submit">
                               Submit
                                </button>
                            </div>
                            
                            </form> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
