import React, { useState } from 'react';

// Tab component
const Tab = ({ label, isActive, onClick }) => (
  <div className={`tab ${isActive ? 'active' : ''}`} onClick={onClick}>
    {label}
  </div>
);

// Tab content component
const TabContent = ({ children }) => (
  <div className="tab-content">
    {children}
  </div>
);

// Tab panel component
const TabPanel = ({ content }) => (
  <div className="tab-panel">
    {content.image && <img src={content.image} alt="Tab Image" />}
    <p>{content.text}</p>
  </div>
);

// Tabs component
export default function Tabs() {
  const [activeTab, setActiveTab] = useState(0);

  // Array of tabs with labels, content, and images
  const tabs = [
    { label: '2016', content: { text: 'The First Startup in 24 hours event', image: 'image/work.png' } },
    { label: '2017', content: { text: 'Content of Tab 2', image: 'image/work.png' } },
    { label: '2018', content: { text: 'Content of Tab 3', image: 'image/work.png' } },
    { label: '2019', content: { text: 'Content of Tab 4', image: 'image/work.png' } },
    { label: '2020', content: { text: 'Content of Tab 5', image: 'image/work.png' } },
    { label: '2021', content: { text: 'Content of Tab 6', image: 'image/work.png' } },
    { label: '2022', content: { text: 'Content of Tab 7', image: 'image/work.png' } },
    { label: '2023', content: { text: 'Content of Tab 8', image: 'image/work.png' } },
  ];

  // Function to handle tab click
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
   <section className='work-history-main'>
     <div className='container-fluid'>
      <div className='row'>
          <div className='work-history'>
            <h3>History of Our Success</h3>
          </div>
        <div className='col-md-12'>
          <div className='our-history-details'>
            <div className="tabs-container">
              {/* Render tab labels */}
              <div className="tab-list">
                {tabs.map((tab, index) => (
                  <Tab
                    key={index}
                    label={tab.label}
                    isActive={index === activeTab}
                    onClick={() => handleTabClick(index)}
                  />
                ))}
              </div>
              {/* Render active tab content */}
              <TabContent>
                <TabPanel content={tabs[activeTab].content} />
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    </div>
   </section>
  );
}
