import React from 'react'
import { Link } from "react-router-dom";

export default function gotaproject() {
  return (
    <div>
         <section className='get-in-touch-main'>
         <div className='container-fluid'>
            <div className='get-in-touch-inner'>
                <div className='row'>
                    <div className='col-lg-8'>
                        <div className='get-in-touch-content'>
                            <h3>Got a project?</h3>
                            <p>Extend your digital presence seamlessly to both mobile and web platforms by leveraging the latest trends and cutting-edge technologies.</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='get-in-touch-btn'>
                        <Link to="/bookfreeconsultation">Book Free Consultation</Link>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    </div>
  )
}
