import React from 'react'
import Gotaproject from './Gotaproject'
import { Link } from "react-router-dom";

export default function () {
  return (
    <div>

    <section className='our-work-main'>
        <div className='container-fluid'>
            <h2>Our Works</h2>
            <div className='row'>
                <div className='col-lg-3'>
                <Link to="/arms">
                    <div className='our-work-inner'>
                        <img src="image/w1.jpg" alt="" />
                        <div className='our-work-inner-details'>
                       <h5>Upgrade Efficiency: Part-Level Repair Revolution</h5>
                       <p>Web Application: Part level Solutions with Inventory</p>
                      <span> Manufacturing & Equipment</span>
                       </div>
                    </div>
                    </Link>
                </div>
                <div className='col-lg-3'>
                  <Link to="/xrms">
                    <div className='our-work-inner'>
                        <img src="image/w2.jpg" alt="" />
                        <div className='our-work-inner-details'>
                       <h5>Speedy Solutions, Elevate Repair Experiences</h5>
                       <p>Web Application: Mobile Repair Solutions with Inventory</p>
                        <span>Mobile Repair Industry </span>
                       </div>
                    </div>
                    </Link>
                </div>
                {/* <div className='col-lg-3'>
                   <Link to="/soc">
                     <div className='our-work-inner'>
                        <img src="image/w3.jpg" alt="" />
                        <div className='our-work-inner-details'>
                       <h5>Innovate Spare Parts Business Solutions</h5>
                      <p>Web Application: SOC</p>
                        <span>Gadget Retail & Repair Industry </span>
                       </div>
                    </div>
                    </Link>
                </div> */}
                <div className='col-lg-3'>
                  <Link to="/sellphone">
                    <div className='our-work-inner'>
                        <img src="image/w4.jpg" alt="" />
                        <div className='our-work-inner-details'>
                       <h5>Sell Smart, Thrive Instantly, Everywhere</h5>
                       <p>Web & Mobile Application: SellPhone</p>
                         <span>Gadget Retail & Repair Industry </span>
                       </div>
                    </div>
                    </Link>
                </div>
               
                
                <div className='col-lg-3'>
                  <Link to="/facultyfeedback">
                    <div className='our-work-inner'>
                        <img src="image/w8.jpg" alt="" />
                        <div className='our-work-inner-details'>
                       <h5>Revolutionize Faculty Feedback: Empower Excellence</h5>
                       <p>Web Application: Faculty Feedback</p>
                        <span>Education Industry</span>
                       </div>
                    </div>
                    </Link>
                </div>
               
                <div className='col-lg-3'>
                  <Link to="/ims">
                    <div className='our-work-inner'>
                        <img src="image/w7.jpg" alt="" />
                        <div className='our-work-inner-details'>
                       <h5>Efficient Inventory: Control, Insights &, Strategic Mastery</h5>
                       <p>Web Application: IMS</p>
                        <span>Supply Chain & Logistics </span>
                       </div>
                    </div>
                    </Link>
                </div>
                <div className='col-lg-3'>
                  <Link to="/assetmanagementsystem">
                    <div className='our-work-inner'>
                        <img src="image/w11.png" alt="" />
                        <div className='our-work-inner-details'>
                       <h5>Strategic Asset Planning: Streamline, Detail.</h5>
                      <p>Web Application: Asset Management</p>
                        <span>Facility Management</span>
                       </div>
                    </div>
                    </Link>
                </div>
                <div className='col-lg-3'>
                 <Link to="/shimlasmartcity">
                    <div className='our-work-inner'>
                        <img src="image/w12.jpg" alt="" />
                        <div className='our-work-inner-details'>
                       <h5>Smart Cities, Seamless Progress: Connect</h5>
                      <p>Web Application: SHIMLA SMART CITY</p>
                        <span> Urban Development </span>
                       </div>
                    </div>
                    </Link>
                </div>
              
            </div>
        </div>
    </section>

<Gotaproject />
  
  <br />
  <br />
  <br />

    </div>
  )
}
