import React from 'react'
import { Link } from "react-router-dom";
export default function SOC() {
  return (
    <div>
          <div>
  <div className='custom-banner'>
        <div className='container-fluid'>
        <div className='banner-content'>
                <h2>Spare Part Sell Management System</h2>
                <p>Welcome to our Spare Part Sell Management System designed to streamline and elevate your gadget-related business! With our innovative software, managing spare parts for all gadgets becomes a breeze. Our inventory management feature ensures that you have real-time control over your stock, making it easy to track, reorder, and optimize your spare part supply. What's more, our user-friendly platform allows customers to effortlessly purchase any gadget parts they need, providing a seamless and convenient shopping experience.</p>
                <Link to="/bookfreeconsultation">Book Free Consultation</Link>
            </div>
        </div>
            <img className='bnr-height' src="image/software.png" alt="" />
</div>

<section className='custom-req-main'>
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-lg-6'>
                <div className='custom-req-inner'>
                    <h3>The Requirement</h3>
                    <p>The previous client's order processing system faced inefficiencies, resulting in delays, errors, and potential customer dissatisfaction due to prolonged order fulfillment times. Concurrently, the manual inventory management process encountered challenges, leading to inaccuracies, potential stockouts, and difficulties in aligning available spare parts with customer demands. The call center module suffered from ineffectiveness, lacking efficient tools for addressing customer inquiries, tracking order statuses, and providing timely updates, thereby resulting in a suboptimal customer service experience with increased call resolution times. Vendor management presented issues with challenges in maintaining relationships, impacting the timely replenishment of stock, negotiation of favorable terms, and overall collaboration, subsequently affecting the availability and pricing of spare parts. Additionally, the process of coordinating on-site repair services encountered difficulties, causing delays, miscommunications, and inefficiencies in dispatching technicians, ultimately affecting the overall customer experience. The absence of robust reporting and analytics features further hindered the ability to gain insights into sales trends, inventory performance, and customer behavior, limiting informed decision-making for optimizing business operations.</p>
                  </div>
            </div>
            <div className='col-lg-6'>
                <div className='custom-req-inner2'>
                    <h3>The Solution</h3>
                    <p>As part of our commitment to your success, we've integrated a Partner Portal that empowers your business associates to make bulk purchases efficiently. This portal simplifies the buying process for your partners, fostering a collaborative and thriving network. For any queries or assistance, our dedicated Call Center is at your service, ready to address customer concerns and provide valuable support. Additionally, our software includes a unique feature that connects you with nearby service providers, ensuring that your customers have access to quick and reliable gadget repair services. Elevate your business with our comprehensive software solution, designed to enhance efficiency, customer satisfaction, and overall success.</p>
                    <h4>Key Features </h4>
                    <ul>
                        <li>User-Friendly </li>
                        <li>Inventory Management</li>
                        <li>Partner Portal</li>
                        <li>Bulk&nbsp;Purchases</li>
                        <li>Call Center</li>
                        <li>Queries</li>
                        <li>Nearby Services</li>
                        <li>Customer Satisfaction</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
        {/* <Gotaproject /> */}

      </div>
    </div>
  )
}
