import React from 'react'
import Gotaproject from '../components/Gotaproject'
import Tabs from '../components/Tabs'

export default function About() {
  return (
    <div>
        <section className='about-us-main'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='about-content'>
                            <h3>We are Amsys</h3>
                            <p>Amsys Infocom Pvt Ltd was established in 2016 with the view to provide cost effective IT solutions to market to bridge the gap of IT needs & affordability. Amsys in managed by highly professionals and experiences team focusing on fulfilling the technical and IT needs of major government and corporate clients.
                            </p>
<p>Amsys is a full service company providing IT infrastructure, networking, data security, software development and consultancy services expertise. We offer complete solutions customized to meet the generic and specific needs of the clients. We are result oriented organisation that tackles industrial challenges of all sizes developing technical solutions that provide significant value for our clients.</p>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='about-img'>
                        <img src="image/about.png" alt="" />
                      </div>
                    </div>
                </div>
                <div className='row custom-space-about'>
                  <div className='col-lg-4'>
                    <div className='years-of-ex'>
                      <h4>7+</h4>
                      <p>Years of experience</p>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='years-of-ex'>
                      <h4>20+</h4>
                      <p>Projects completed</p>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='years-of-ex'>
                      <h4>90%</h4>
                      <p>Client retention</p>
                    </div>
                  </div>
                </div>
            </div>
        </section>

        <section className='core-vlues-main'>
            <div className='container-fluid'>
              <h2>Our values are the driving force behind 
everything we do at Amsys</h2>
                <div className='row'>
                  <div className='col-lg-4'>
                    <div className='about-viedos'>
                      <h4>Our Values</h4>
                      <video playsinline="playsinline" muted="muted" preload="yes" autoplay="autoplay" loop="loop" id="vjs_video_739_html5_api" class="video-js" data-setup='{"autoplay":"any"}'>
    		<source src="image/videos.mp4" type="video/mp4"/>
      </video>
                    </div>
                  </div>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <div className='core-value-text'>
                          <h6><img src="image/icon.png" alt="" />Build Fast</h6>
                          <p>With speed comes faster learnings. We believe in continuous improvements so that our users get more value faster and we can learn rapidly.</p>
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='core-value-text'>
                          <h6><img src="image/icon.png" alt="" />Quantum Innovation</h6>
                          <p>We embrace a quantum mindset, fostering innovation that transcends boundaries and propels us into the digital frontier.</p>
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='core-value-text'>
                          <h6><img src="image/icon.png" alt="" />Iterate everything</h6>
                          <p>Everything we do at Maze is an iteration to the next step. We don’t strive for immediate perfection but rather take an experimental approach to everything we do</p>
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='core-value-text'>
                          <h6><img src="image/icon.png" alt="" />Informed by data</h6>
                          <p>We believe in the power of data & this is why we use data as the basis of our decision-making but we also believe in going with your gut feeling from time to time. We’re human after all.</p>
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='core-value-text'>
                          <h6><img src="image/icon.png" alt="" />Maximised Collaboration</h6>
                          <p>At the core of our approach is Maximised Collaboration, where every partnership and integration is carefully coordinated for the greatest impact. </p>
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='core-value-text'>
                          <h6><img src="image/icon.png" alt="" />Integrated Cybersecurity Harmony</h6>
                          <p>We fuse technology and resilience for cybersecurity harmony, creating a secure tech ecosystem that seamlessly integrates innovation and safety.</p>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </section>
       
      <div className='cutom-cta-n'>
      <Gotaproject />
      </div>
<br /><br />
{/* <Tabs />

<section className='img-abt'>
  <img src="image/abt.png" alt="" />
</section> */}

    </div>
  )
}
