import React from 'react'
import { Link } from "react-router-dom";
export default function VideoConferencingTool() {
  return (
    <div>
           <div>
        <div className='custom-banner'>
        <div className='container-fluid'>
        <div className='banner-content'>
                <h2>Video Conferencing Tool</h2>
               <p>Tired of emails? Try Amsys Video Conferencing Tool for easy video chats! It's simple   clear video, live recordings, shared notes, and easy collaboration. Perfect for meetings and presentations. No tech fuss – just sign up and connect instantly! Goodbye, email stress! Hello, effortless face-to-face talks!</p>
                <Link to="/bookfreeconsultation">Book Free Consultation</Link>
            </div>
        </div>
            <img className='bnr-height' src="image/software.png" alt="" />
        </div>

       <section className='custom-work-details-main'>
          <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-3'>
                    <div className='work-sidebar'>
                       <div className='work-inner'>
                        <h6>Client</h6>
                        <h3>Aforserve.com Limited</h3>
                       </div>
                       <div className='work-inner'>
                        <h6>Industry</h6>
                        <h3>Education</h3>
                       </div>
                       <div className='work-inner'>
                        <h6>Service</h6>
                        <h3>UI/UX Design</h3>
                       </div>
                       <div className='work-inner'>
                        <h6>Team Setup</h6>
                        <h3>1 Researcher + 1 Designer</h3>
                       </div>
                       <div className='work-inner'>
                        <h6>Timeline</h6>
                        <h3>9 to 10 Months</h3>
                       </div>
                    </div>
                </div>
                <div className='col-lg-9'>
                    <div className='work-details-right'>
<p>Experience a new era of communication with Amsys. Our Video Conferencing Tool offers simplicity and effectiveness, allowing you to communicate visually with ease. Enjoy live recordings and shared notes for enhanced collaboration. Sign up, connect instantly, and leave behind the stress of overflowing email inboxes. With Amsys, virtual interactions become seamless and engaging, transforming the way you communicate.</p>
                        <h4>Key Features </h4>
                        <ul className='custom-ul'>
                            <li>Record your session with the recording button.</li>
                            <li>Engage in public chat easily with the public chat button.</li>
                            <li>Collaborate efficiently with shared notes.</li>
                            <li>Keep track of participants with the users list.</li>
                            <li>Take charge with moderator controls.</li>
                            <li>Access additional options based on your role.</li>
                            <li>Showcase your presentation by uploading it.</li>
                            <li>Share external videos seamlessly.</li>
                            <li>Manage your audio, video, and screen sharing options conveniently.</li>
                            <li>Display your presentation easily with the "Show Presentation" feature.</li>
                            <li>Customize your settings to suit your preferences.</li>
                        </ul>
                    </div>
                </div>
            </div>
          </div>
       </section>


        {/* <Gotaproject /> */}

  

    </div>
    </div>
  )
}
