import React from 'react'
import Gotaproject from './Gotaproject.js'
import { Link } from "react-router-dom";

export default function Mobileappdevelopment() {
  return (
    <div>
        <div>
        <div className='custom-banner'>
        <div className='container-fluid'>
        <div className='banner-content'>
                <h2>MobApp Development</h2>
                <p>Revolutionize mobile app development with our cutting-edge solutions, expertly crafting dynamic applications for Android, iOS, and Hybrid platforms. Tailored to align with your business goals, we captivate users and elevate your brand presence. </p>
                <p>Whether targeting Android's vast user base, iOS's sophisticated ecosystem, or opting for versatile Hybrid development, we optimize experiences and leverage the latest technology. Committed to bringing your vision to life, our digital masterpieces stand out in the evolving digital landscape.</p>
                <Link to="/bookfreeconsultation">Book Free Consultation</Link>
            </div>
        </div>
            <img className='bnr-height' src="image/mobile-banner.png" alt="" />
        </div>

        <section className='transform-your-business software-development'>
            <div className='container-fluid'>
                <div className='business-heading'>
                    <h2>Mobile App Development</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Android Development</h3>
                            <p>Tailored for the vast Android user base, our development team crafts dynamic, native applications. We unleash the full potential of the Android platform, ensuring a seamless and engaging user experience. Your audience experiences the power of our bespoke apps, perfectly aligned with Android's capabilities.</p>
                            </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>iOS Development</h3>
                            <p>Our delicately created native apps will help you promote your brand more effectively within the complex iOS ecosystem. Our emphasis on use platform-specific features and improving user experience makes sure your app stands out on Apple devices. Enhance your brand's view on iOS by giving users a smooth and unique experience.</p>
                            </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Hybrid Development</h3>
                        <p>Experience versatility with our Hybrid Development solutions, reaching both Android and iOS users cost-effectively. Our applications seamlessly adapt, providing a unified and efficient cross-platform user experience. Break boundaries and expand your reach, delivering excellence across diverse devices.</p>    
                        </div>
                    </div>
                    
                </div>
               
            </div>
        </section>


        <Gotaproject />

        <section className='transform-your-business '>
            <div className='container-fluid'>
                <div className='business-heading'>
                    <h2><span>Key Benefits</span> of Mobile App
development</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <span><img src="image/app1.png" alt="" /></span>
                            <h3>Enhanced User Engagement</h3>
                            <p>Elevate user engagement through the creation of intuitive mobile applications that not only captivate but also effectively retain audiences. Our focus on crafting user-centric experiences ensures that your mobile apps go beyond functionality, creating a lasting connection between your brand and users.</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/app2.png" alt="" /></span>
                            <h3>Seamless Accessibility</h3>
                            <p>Increase your global awareness by providing easy and immediate access to your services at any time or location. Our commitment to providing a seamless user experience ensures that your products and services transcend national borders, building a vibrant and inclusive ties to your audience worldwide.</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content pd-right'>
                        <span><img src="image/app3.png" alt="" /></span>
                            <h3>Optimised Performance</h3>
                           <p>Deliver an exceptional user experience with our high-performance mobile applications, ensuring seamless and responsive interactions. Our focus on optimized performance sets your applications apart, providing users with a smooth and delightful experience, enhancing their satisfaction and loyalty.</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/app4.png" alt="" /></span>
                            <h3>Brand Visibility and Recognition</h3>
                       <p>Increase your global presence with our mobile app development, which ensures easy access from any location. Our emphasis on a seamless user experience crosses borders, fostering inclusive connections around the world. Increase your global reach by giving people immediate access to your products & services.</p>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/app5.png" alt="" /></span>
                            <h3>Data Security</h3>
                            <p>Ultimately, we set priorities trust among clients in our mobile app development by implementing robust data security measures. Our unwavering commitment creates a secure environment, fostering confidence and positioning your brand as a reputable a trustee of highly confidential information.</p>
                            </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content pd-right'>
                        <span><img src="image/app6.png" alt="" /></span>
                            <h3>Strategic Business Growth</h3>
                         <p>Drive strategic business growth by harnessing the power of mobile technology to streamline operations, elevate customer satisfaction, and propel overall expansion. Our innovative solutions empower your business to adapt to changing landscapes, fostering agility and sustainable growth in today's dynamic marketplace.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

<section className='custom-faq'>
    <div className='container-fluid'>
        <div className='faq-inner'>
        <div className="accordion">
    <h1>Frequently Asked Questions</h1>
    <div className="accordion-item">
        <input type="checkbox" id="accordion1" />
        <label for="accordion1" className="accordion-item-title"><span className="icon"></span> How long does it take to develop a mobile app?</label>
        <div className="accordion-item-desc">Development timelines vary based on complexity and features, but our agile approach ensures efficient and prompt delivery.</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion2" />
        <label for="accordion2" className="accordion-item-title"><span className="icon"></span>Which platform is better for my app, Android or iOS?</label>
        <div className="accordion-item-desc">The choice depends on your target audience; we offer expertise in both Android and iOS development, tailoring solutions to your business objectives.
</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion3" />
        <label for="accordion3" className="accordion-item-title"><span className="icon"></span>Can you assist with app maintenance and updates after launch?</label>
        <div className="accordion-item-desc">  Absolutely, we provide ongoing support to ensure your app remains current, secure, and aligned with evolving industry standards.

</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion4" />
        <label for="accordion4" className="accordion-item-title"><span className="icon"></span>What security measures are in place to protect user data?</label>
        <div className="accordion-item-desc"> We prioritize data security, implementing robust measures to safeguard user information and ensure compliance with industry standards.

</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion5" />
        <label for="accordion5" className="accordion-item-title"><span className="icon"></span>How can a mobile app benefit my business compared to a website?</label>
        <div className="accordion-item-desc"> Mobile apps offer enhanced user engagement, accessibility, and performance, providing a personalized and convenient experience that often surpasses traditional websites.
</div>
    </div>

</div>
        </div>
    </div>
</section>

    </div>
    </div>
  )
}
