import React from 'react'

export default function Form() {
  return (
    <div>
        <section className='project-form-main'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='footer-inner project-enq'>
                            <h3>For Project Enquiry</h3>
                            <p className='pd-l'>Email, call, or complete the form to learn how Amsys can solve your Problem.</p>
                            <p><span><img src="image/call.png" alt="" /></span><a href="tel: 0120- 5058563"> +91-120-5058563</a></p>
                            <p><span><img src="image/mail.png" alt="" /></span><a href="mailto:contact@amsysinfo.com">contact@amsysinfo.com</a></p>
                        
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='project-enq-form'>
                            <form action="">
                              <div className='form-group'>
                                <label htmlFor="">Name</label>
                              <input type="text" />
                              </div>
                              <div className='form-group'>
                                <label htmlFor="">Email</label>
                              <input type="text" />
                              </div>
                              <div className='form-group'>
                                <label htmlFor="">Name</label>
                                <select>
                                    <option value="Select">Select</option>
                                    <option value="someOption">Some option</option>
                                    <option value="otherOption">Other option</option>
                                </select>
                              </div>
                              <div className='form-group'>
                                <label htmlFor="">Phone Number</label>
                              <input type="text" />
                              </div>
                              <div className='form-group'>
                                <a href="" class="btn-footer">Submit</a>
                              </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
