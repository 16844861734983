import React from 'react'
import { Link } from "react-router-dom";

export default function Career() {
  return (
    <div>
        <div className='container-fluid'>
        <div className='career-main'>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className='career-list'>
                        <h3>IT Sales Representative</h3>
                        <div className='career-list-inner'>
                            <span>Experience: 2 - 3 years</span>
                            <Link to="/careerdetails">Explore More</Link>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='career-list'>
                        <h3>IT Sales Consultant</h3>
                        <div className='career-list-inner'>
                            <span>Experience: 2 - 3 years</span>
                            <Link to="/careerdetails">Explore More</Link>
                        </div>
                    </div>
                </div>
               
               
            </div>
        </div>
        </div>
    </div>
  )
}
