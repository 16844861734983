import React from 'react'
import Gotaproject from './Gotaproject.js'
import { Link } from "react-router-dom";

export default function Cybersecurity() {
  return (
    
    <div>
         <div>
        <div>
        <div className='custom-banner'>
        <div className='container-fluid'>
        <div className='banner-content'>
                <h2>Cyber Security</h2>
              <p>In the ever-changing cyber scene, our solutions serve as a fortress, precisely crafted to defend your digital assets. Robust firewalls, powerful encryption, and proactive threat detection are the foundations of our holistic strategy, which ensures your systems' resilience. We provide vulnerability assessments, penetration testing, security audits, and customised training as part of a range of services designed to proactively strengthen your defences. Navigate the digital world with confidence, securing sensitive data and winning trust with every step.</p>
              <Link to="/bookfreeconsultation">Book Free Consultation</Link>
            </div>
        </div>
            <img className='bnr-height' src="image/cyber-banner.png" alt="" />
        </div>

        <section className='transform-your-business software-development'>
            <div className='container-fluid'>
                <div className='business-heading'>
                    <h2>Cyber Security</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Information System Audit </h3>
                            <p>Elevate your security posture with our IS Audit services, conducting thorough evaluations of information system controls. Identify vulnerabilities, ensure regulatory compliance, and align seamlessly with industry best practices. Our meticulous approach safeguards your operations and enhances overall cybersecurity resilience.</p>
                            </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Security Operation Centre</h3>
                            <p>Empower your security with our Security Operation Centre, delivering proactive monitoring and swift incident response. Continuously improve defenses using real-time insights, creating a dynamic shield against emerging threats. Trust in our SOC for a robust defense mechanism, ensuring the resilience of your cybersecurity.</p>
                           </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Security Assessment</h3>
                            <p>Elevate security with our thorough system assessment, implementing rigorous measures to mitigate risks effectively. Regular updates and hardening fortify defense mechanisms, creating a resilient environment. Trust us for a comprehensive approach that safeguards your system's security posture with unwavering strength.</p>
                            </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3> Penetration Testing</h3>
                            <p>Through systematic vulnerability identification and simulated cyber-attacks, we offer actionable insights to enhance your security posture. Proactively address weaknesses and fortify defenses with our comprehensive approach. Trust our expertise to provide preemptive solutions that strengthen your cybersecurity.</p>
                           
                            </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Vulnerability Assessment</h3>
                            <p>By identifying vulnerabilities and simulating cyberattacks, we provide you with actionable information to improve your security. Make proactive use of our all-encompassing strategy to strengthen defences and resolve vulnerabilities. Put your trust in our skills to deliver proactive ideas that boost your cybersecurity.</p>
                            </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <h3>Consultant Training</h3>
                          <p>With our customised consultant training programmes, which impart the most current cybersecurity standards, you can inspire your staff. Our training offerings are designed to provide your staff with the abilities and knowledge essential to effectively navigate the constantly evolving cybersecurity world.</p>
                          </div>
                    </div>
                    
                </div>
               
            </div>
        </section>


        <Gotaproject />

        <section className='transform-your-business '>
            <div className='container-fluid'>
                <div className='business-heading'>
                    <h2><span>Key Benefits</span> of Cyber Security</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                            <span><img src="image/b1.png" alt="" /></span>
                            <h3>Data Security</h3>
                           <p>Safeguard the integrity and confidentiality of your data with advanced encryption and secure protocols, ensuring compliance with regulatory standards. Our robust data protection measures are designed to meet the highest industry benchmarks.</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/s2.png" alt="" /></span>
                            <h3>Cost-Saving</h3>
                        <p>Embracing cyber security translates into substantial cost savings for businesses. The tailored nature of these solutions ensures a targeted investment, sparing companies from unnecessary expenses on features that don't align with their needs.
</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content pd-right'>
                        <span><img src="image/b2.png" alt="" /></span>
                            <h3>Customer Trust and Reputation</h3>
                         <p> Bolster customer trust and protect your brand reputation by demonstrating a commitment to robust cyber security practices, fostering a secure digital environment. Our dedication to customer trust goes hand in hand with implementing state-of-the-art security measures.</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/b3.png" alt="" /></span>
                            <h3>Risk Mitigation</h3>
                         <p>Proactively identify and mitigate potential cyber threats, minimizing the risk of data breaches and unauthorized access to sensitive information. Our comprehensive approach ensures a vigilant stance against evolving cyber risks, providing a secure foundation for your digital assets.</p>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content'>
                        <span><img src="image/b4.png" alt="" /></span>
                            <h3>Operational Continuity</h3>
                           <p>Enhance business resilience by implementing robust cyber security measures, safeguarding critical systems, and ensuring uninterrupted operations. Our solutions go beyond threat mitigation, actively fortifying your organisation to maintain operational continuity.</p>
                            </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='inner-content pd-right'>
                        <span><img src="image/b5.png" alt="" /></span>
                            <h3>Compliance Assurance</h3>
                       <p>Align with industry regulations and standards, ensuring that your organization remains compliant with legal requirements and industry-specific cyber security guidelines. Our tailored solutions ensure not only security but also adherence to evolving compliance frameworks.</p>
                        </div>
                    </div>
                </div>
               
            </div>
        </section>

        <section className='custom-faq'>
    <div className='container-fluid'>
        <div className='faq-inner'>
        <div className="accordion">
    <h1>Frequently Asked Questions</h1>
    <div className="accordion-item">
        <input type="checkbox" id="accordion1" />
        <label for="accordion1" className="accordion-item-title"><span className="icon"></span> How vulnerable is my organization to cyber threats?</label>
        <div className="accordion-item-desc">We conduct comprehensive assessments and penetration testing to identify vulnerabilities and assess your organization's susceptibility to cyber threats.
</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion2" />
        <label for="accordion2" className="accordion-item-title"><span className="icon"></span>What measures can I take to protect sensitive data from cyber attacks?</label>
        <div className="accordion-item-desc"> Our cyber security solutions include advanced encryption, secure protocols, and continuous monitoring to protect sensitive data from unauthorized access and breaches.
</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion3" />
        <label for="accordion3" className="accordion-item-title"><span className="icon"></span>How does cyber security contribute to regulatory compliance?</label>
        <div className="accordion-item-desc">Our cyber security practices ensure compliance with industry regulations and standards, providing the necessary safeguards to meet legal requirements.
</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion4" />
        <label for="accordion4" className="accordion-item-title"><span className="icon"></span> In the event of a cyber attack, what is the incident response plan?</label>
        <div className="accordion-item-desc"> We have a robust incident response plan in place, including real-time threat detection and swift action to mitigate and recover from any potential cyber security incidents.

</div>
    </div>

    <div className="accordion-item">
        <input type="checkbox" id="accordion5" />
        <label for="accordion5" className="accordion-item-title"><span className="icon"></span>Can employees be trained to recognize and prevent cyber threats?</label>
        <div className="accordion-item-desc">Yes, we offer tailored training programs to educate employees on cyber security best practices, empowering them to recognize and prevent potential threats effectively.</div>
    </div>

</div>
        </div>
    </div>
</section>

    </div>
    </div>
    </div>

  )
}
