const SliderData =
    [
        {
            id: 1,
            image: "image/banner.png",
            shortimage: "image/banner-icon.png",
            hadd:"Software Development",
            linkurl: '/softwaredevelopment',
            parr:"Extend your digital presence seamlessly to both mobile and web platforms by leveraging the latest trends and cutting-edge technologies."
        },
        {
            id: 2,
            image: "image/cyber-bnr.png",
            shortimage: "image/banner-icon.png",
            hadd:"Cyber Security",
            linkurl: '/cybersecurity',
            parr:"We offer a complete solution for all cyber security needs of every organisation focusing mainly on high quality security testing."
        },
        {
            id: 3,
            image: "image/erp-bnr.png",
            shortimage: "image/banner-icon.png",
            linkurl: '/erpsolutions',
            hadd:"ERP Solutions",
            parr:"Efficient, growth-focused ERP solutions for sustained excellence. Elevate your enterprise without breaking a sweat."
        },
        {
            id: 4,
            image: "image/mobile-bnr.png",
            shortimage: "image/banner-icon.png",
            linkurl: 'mobileappdevelopment',
            hadd:"Mobile App Development",
            parr:"Navigate confidently with our Business Intelligence insights. Precision meets intuition for business success."
        },
        {
            id: 5,
            image: "image/data-bnr.png",
            shortimage: "image/banner-icon.png",
            linkurl: '/datacentersolutions',
            hadd:"Data Centre Solutions",
            parr:"Effortlessly unite systems for a harmonized digital ecosystem. Simplify tomorrow with a touch of synchronized efficiency."
        },
        {
            id: 6,
            image: "image/cloud-banner.png",
            shortimage: "image/banner-icon.png",
            linkurl: '/cloudsolutions',
            hadd:"Cloud Computing",
            parr:"Get in touch with us to protect your data, computing environments, applications running  whether in the cloud or in on-premise environment."
        }
    ]

export default SliderData;