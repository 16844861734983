import React from 'react';
import { Link } from "react-router-dom";
import { useForm, ValidationError } from '@formspree/react';

export default function BookFreeConsultation() {
  const [state, handleSubmit] = useForm("mzbngreo");

  if (state.succeeded) {
    return <div className='custom-thankyou'>
      <img src="image/thankyou.png" alt="" />
      <p>Thanks for your interest! Our sales team will contact you shortly.</p>
      <Link to="/">OK</Link>
    </div>
  }

  return (
    <div>
      <section className='project-form-main'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='project-enq-form'>
                <form onSubmit={handleSubmit}>
                  <div className='form-group'>
                    <label htmlFor='name'>Name</label>
                    <input type='text' id='name' name='name' required />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='email'>Email</label>
                    <input type='email' id='email' name='email' required />
                    <ValidationError 
                      prefix="Email" 
                      field="email"
                      errors={state.errors}
                    />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='phone'>Phone Number</label>
                    <input type='tel' id='phone' name='phone' required />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='services'>Select Services</label>
                    <select id='services' name='services' required>
                      <option value='Select'>Select</option>
                      <option value='softwaredevelopment'>Software Development</option>
                      <option value='Mobileappdevelopment'>Mobile App Development</option>
                      <option value='CloudSolutions'>Cloud Solutions</option>
                      <option value='cybersecurity'>Cyber Security</option>
                      <option value='datacentersolutions'>Data Center Solutions</option>
                      <option value='erpsolutions'>ERP Solutions</option>
                      <option value='other'>Other</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <label htmlFor='msg'>Message</label>
                    <textarea type='text' id='msg' name='msg' required />
                  </div>
                 
                  <div className='form-group'>
                    <button type='submit' class="btn-submit" disabled={state.submitting}>
                    Book Free Appointment
                    </button>
                  </div>
                  
                </form>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='footer-inner project-enq'>
                <h3>What are the next steps?</h3>
                <ul className='custom-enquiry'>
                  <li><span>1</span>Our sales team reaches you within a few hours to organise a FREE consultation.</li>
                  <li><span>2</span>Meanwhile, we sign an NDA to ensure the highest privacy level and protection of your idea.</li>
                  <li><span>3</span>We will conduct a FREE consultation to understand your business requirements and will answer any questions you may have.</li>
                  <li><span>4</span>Our expert strategist presents project estimates and approximate timelines.</li>
                </ul>
                <p className='more-ques'><b>Got more questions?</b> We’re here to help. Call us on</p>
                <p><span><img src="image/call.png" alt="" /></span><a href="tel: 0120- 5058563"> +91-9818017487</a></p>
                <p><span><img src="image/call.png" alt="" /></span><a href="tel: 0120- 5058563"> +91-120-5058563</a></p>
                <p><span><img src="image/mail.png" alt="" /></span><a href="mailto:contact@amsysinfo.com">contact@amsysinfo.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
